import { useEffect, useState } from "react";

export default function ImageFallback({ src, fallBackImg, ...rest }) {
    const [imgSrc, setImgSrc] = useState(src !== null && src !== ''? src: fallBackImg);
    useEffect(() => {
        setImgSrc(src);
    }, [src])

    return (
        <img
            {...rest}
            src={imgSrc}
            onLoad={(result) => {
                if (result.naturalWidth === 0) {
                    setImgSrc(fallBackImg);
                }
            }}
            onError={() => {
                setImgSrc(fallBackImg);
            }}
        />
    )
}
