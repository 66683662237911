import classnames from 'classnames'
import { Nav } from 'react-bootstrap'
import Icon from '../utils/Icon'

export default function SportsNavbar({ settings, sport, onSelect = () => {}, isMobile, onItemClick }) {
  const isDarkTheme = settings.tema_padrao === 1
  const colorIcons = {
    'soccer': '/icons/soccer.svg',
    'basketball': '/icons/basketball.svg',
    'live': '/icons/live.svg',
    'volley': '/icons/volley.svg',
    'e-sports': '/icons/esports.svg',
    'tennis': '/icons/tennis.svg',
    'mma': '/icons/boxing.svg',
    'casino': '/icons/casino.svg'
  }

  const sportItem = (key, icon, label) => {
    let isActive = false

    if (key === 'live') {
      isActive = settings.habilitar_ao_vivo
    } else if (key === 'casino') {
      isActive = settings.modulo_cassino && settings.habilitar_cassino
    } else if (settings.esportes) {
      sport = settings.esportes[key]
      isActive = !!sport

      if (isActive) {
        label = sport.name
      }
    }

    if (!isActive) return null

    return (
      <Nav.Item>
        <Nav.Link eventKey={key} className={classnames({ 'pb-0': isMobile })}>
          {isMobile ? <div className="d-inline-block">
            <div className="d-flex flex-row justify-content-center align-items-center text-center">
              {settings.icones_coloridos
                ? <img src={colorIcons[key]} width={36} height={36} />
                  : <Icon name={icon} className="display-sport" />}
              <p className="mb-0 ml-2">{label}</p>
            </div>
          </div>
            : <div className="text-center">
            <span className="display-5">
              {settings.icones_coloridos
                  ? <img src={colorIcons[key]} width={40} height={40} />
                : <Icon name={icon} />}
            </span>
            <p className="mb-0">{label}</p>
          </div>}
        </Nav.Link>
      </Nav.Item>
    )
  }

  const handleClick = (key) => {
    onSelect(key)
    onItemClick('sports')
  }

  return (
    <div className={classnames([{'scroll-mobile pb-0': isMobile, 'flex-column sidebar-left': !isMobile, 'bg-brown': !isMobile && isDarkTheme }])}>
      <div className={isMobile ? "sports-nav" : "sidebar-sticky"}>
        <Nav className={isMobile ? 'd-flex flex-row flex-nowrap mb-1' : 'flex-column'} activeKey={sport} onSelect={(key) => handleClick(key)}>
          {sportItem('casino', 'dice', 'Cassino')}
          {sportItem('soccer', 'futbol', 'Futebol')}
          {sportItem('live', 'signal-stream', 'Ao vivo')}
          {sportItem('basketball', 'basketball-hoop', 'Basquete')}
          {sportItem('mma', 'boxing-glove', 'Lutas')}
          {sportItem('volley', 'volleyball-ball', 'Voleibol')}
          {sportItem('tennis', 'tennis-ball', 'Tênis')}
          {sportItem('e-sports', 'gamepad-alt', 'E-Sports')}
        </Nav>
      </div>
    </div>
  )
}
