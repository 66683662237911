import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import Icon from '../utils/Icon'

export default function Popup({ settings, defaultShow = true }) {
  const [show, setShow] = useState(defaultShow)

  // useEffect(() => {
  //   let _show = localStorage.getItem('show_popup')
  //   if (_show === null) {
  //     localStorage.setItem('show_popup', 1)
  //     _show = true
  //   } else {
  //     _show = !!(+_show)
  //   }
  //   setShow(_show)
  // })

  useEffect(() => {
    setShow(defaultShow)
  }, [defaultShow])

  const onHide = () => {
    setShow(false)
    // localStorage.setItem('show_popup', 0)
  }

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      style={{ maxHeight: '100vh' }}
      centered
      contentClassName="align-items-center mx-auto w-auto"
    >
      <a
        className="position-absolute text-white mt-1 mr-2"
        style={{ right: 0, cursor: 'pointer' }}
        onClick={() => onHide()}
      >
        <Icon name="times" />
      </a>
      <img src={`${settings.popup}`} className="img-responsive" alt="Banner" />
    </Modal>
  )
}
