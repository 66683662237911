import { Row, Col, Card, Button } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'

import DefaultSpinner from "../layout/DefaultSpinner"
import Icon from '../utils/Icon'
import ImageFallback from "../utils/Image"
import styles from '../../styles/CardCasino.module.css'

export default function CardsCassino({ isMobile, isDarkTheme, games, user, settings, hasNextPage, setSelectedGame, setCurrentPage }) {
  const handlePlay = (game) => {
    if (!user) {
      alertify.alert('Erro', 'Você precisa fazer cadastro para jogar no cassino')

      return
    }

    if (user.nivel != 3) {
      alertify.alert('Erro', 'Apenas apostadores podem jogar no cassino')

      return
    }

    setSelectedGame(Object.assign({ type: 'start' }, game))
  }

  const renderCards = () => {
    if (isMobile) {
      return (
        <Row>
          {games.map(game => {
            return (
              <Col xs={6} className='my-2' key={game.id}>
                <Card className={styles.cardCasino}>
                  <Card.Img
                    as={ImageFallback}
                    variant="top"
                    className={styles.cardImg}
                    src={game.image}
                    fallBackImg={'/images/cassino/game.jpg'}
                  />
                  <div className={styles.cardBackground}>
                    <Card.Title className={styles.cardCasinoTitle}>
                      <p className='text-white'>{game.name}</p>
                    </Card.Title>
                    <div className={styles.cardCasinoButtons}>
                      {settings.habilitar_demo && game.is_demo_enabled && <Button variant="secondary" size="sm" className='mx-1 ' onClick={() => setSelectedGame(Object.assign({ type: 'demo' }, game))}>
                        <Icon name='joystick' margin /> Demo
                      </Button>}
                      <Button variant="primary" size="sm" className='mx-1' onClick={() => handlePlay(game)}>
                        <Icon name='play' margin /> Jogar
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            )
          })}
        </Row>
      )
    }

    return (
      <Row className='cards-casino m-2'>
        {games.map(game => {
          return (
            <Col md={3} className='my-2' key={game.id}>
              <Card className={styles.cardCasino}>
                <Card.Img
                  as={ImageFallback}
                  variant="top"
                  className={styles.cardImg}
                  src={game.image}
                  fallBackImg={'/images/cassino/game.jpg'}
                />
                <div className={styles.cardBackground}>
                  <Card.Title className={styles.cardCasinoTitle}>
                    <p className='text-white'>{game.name}</p>
                  </Card.Title>
                  <div className={styles.cardCasinoButtons}>
                    {settings.habilitar_demo && game.is_demo_enabled && <Button variant="secondary" onClick={() => setSelectedGame(Object.assign({ type: 'demo' }, game))}>
                      <Icon name="joystick" margin /> Demo
                    </Button>}
                    <Button variant="primary" onClick={() => handlePlay(game)}>
                      <Icon name='play' margin /> Jogar
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <InfiniteScroll
      className="mx-0"
      dataLength={games.length}
      next={() => setCurrentPage(old => old + 1)}
      hasMore={hasNextPage}
      loader={<Col><DefaultSpinner height={`${isMobile ? 8 : 10}vh`} isDarkTheme={isDarkTheme} /></Col>}
      scrollableTarget={isMobile ? null : 'page-wrapper'}
      scrollThreshold={isMobile ? 0.98 : 0.8}
    >
      {renderCards()}
    </ InfiniteScroll>
  )
}
