import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import classnames from 'classnames'

import styles from '../../styles/GameModal.module.css'
import Icon from '../utils/Icon'
import { fetcher } from '../../utils'

export default function CasinoGameModal({ game, axios, isDarkTheme, setSelectedGame, refreshBalance }) {
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (game == null) {
      return
    }

    if (game.type == 'demo') {
      setUrl(`https://painel.franquia.bet/api/casino/demo/${game.id}`)

      return
    }

    fetcher(axios, `/api/iniciar_jogo?jogo=${game.id}`).then(({ data }) => {
      if (!data.resposta) {
        alertify.alert(data.mensagem, data.descricao)
        setSelectedGame(null)

        return
      }

      setUrl(data.url)
    })
  }, [game])

  const handleHide = () => {
    setSelectedGame(null)

    if (game.type != 'demo') {
      refreshBalance()
    }
  }

  return (
    <Modal show={game != null} size="xl" dialogClassName='my-0 modal-90w' onHide={() => handleHide()}>
      <Modal.Header >
        <Modal.Title className={classnames({ 'text-white': isDarkTheme })}>
          {game && game.name.toString().replace(' Mobile', '')}
        </Modal.Title>
        <Button variant={isDarkTheme ? 'dark' : 'light'} className={classnames('text-right mt-1', { 'text-white': isDarkTheme })} onClick={() => handleHide()}>
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <iframe className={styles.casinoGame} frameBorder='0' src={url}></iframe>
      </Modal.Body>
    </Modal>
  )
}
