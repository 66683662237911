import { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';

import CardsCassino from './CardsCassino';
import CasinoGameModal from './CasinoGameModal';
import SearchCassinoGame from './SearchGame';
import BannersCarrousel from '../layout/BannersCarrousel';
import { fetcher } from '../../utils';

const GAMES_PER_PAGE = 20;

export default function CasinoGames({
  axios,
  settings,
  user,
  gamesLength,
  setGamesLength,
  isMobile,
  isDarkTheme,
  refreshBalance
}) {
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [queryName, setQueryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [queryCategory, setQueryCategory] = useState(0);
  const [providers, setProviders] = useState([]);
  const [queryProvider, setQueryProvider] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const timeout = useRef(undefined);

  useEffect(() => {
    setQueryCategory(0);
  }, [queryProvider]);

  useEffect(() => {
    setCurrentPage(1);
  }, [isMobile, queryProvider, queryCategory, queryName]);

  useEffect(() => {
    const params = {
      mobile: +isMobile,
      pagina: currentPage
    };

    if (queryCategory > 0) {
      params.categoria = queryCategory;
    }

    if (queryProvider > 0) {
      params.provedor = queryProvider;
    }

    if (!+queryProvider && !+queryCategory) {
      params.favoritos = 1;
    }

    if (queryName != '') {
      params.nome = queryName;
    }

    const body = new URLSearchParams(params).toString();

    const loadGames = () => {
      fetcher(axios, `/api/jogos_cassino?${body}`).then(({ data }) => {
        if (!data.resposta) {
          alertify.alert(data.mensagem, data.descricao);

          return;
        }

        let _games = games;

        if (currentPage == 1) {
          _games = [];
        }

        _games = _games.concat(data.jogos);

        setGamesLength(data.total_jogos);
        setGames(_games);

        const currentTotal = GAMES_PER_PAGE * currentPage;

        setHasNextPage(currentTotal < data.total_jogos);

        if (data.categorias) {
          setCategories(data.categorias);
        }

        if (data.provedores) {
          setProviders(data.provedores);
        }
      });
    };

    if (timeout.current != undefined) {
      clearTimeout(timeout.current);
      setHasNextPage(false);
    }

    if (currentPage == 1) {
      setGames([]);
      setHasNextPage(true);
    }

    if (queryName != '') {
      timeout.current = setTimeout(() => {
        loadGames();
      }, 500);
    } else {
      loadGames();
    }
  }, [currentPage, queryCategory, queryProvider, queryName]);

  return (
    <>
      {settings?.esportes?.length < 1 ? (
        <BannersCarrousel banners={settings.banners} />
      ) : null}
      <Container className="mt-3 overflow-x-hidden" fluid id="container">
        <SearchCassinoGame
          isMobile={isMobile}
          isDarkTheme={isDarkTheme}
          queryName={queryName}
          queryCategory={queryCategory}
          queryProvider={queryProvider}
          providers={providers}
          categories={categories}
          setQueryName={setQueryName}
          setQueryCategory={setQueryCategory}
          setQueryProvider={setQueryProvider}
        />
        <CardsCassino
          isMobile={isMobile}
          isDarkTheme={isDarkTheme}
          games={games}
          user={user}
          settings={settings}
          currentPage={currentPage}
          hasNextPage={hasNextPage}
          gamesLength={gamesLength}
          setSelectedGame={setSelectedGame}
          setCurrentPage={setCurrentPage}
          setHasNextPage={setHasNextPage}
        />
      </Container>
      <CasinoGameModal
        isDarkTheme={isDarkTheme}
        game={selectedGame}
        axios={axios}
        settings={settings}
        setSelectedGame={setSelectedGame}
        refreshBalance={refreshBalance}
      />
    </>
  );
}
