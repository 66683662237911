import classnames from 'classnames'

import CasinoGames from '../cassino/GamesCasino'
import DefaultSpinner from '../layout/DefaultSpinner'
import SportsNavbar from '../layout/SportsNavbar'
import SportChampionshipOdds from './SportChampionshipOdds'
import LiveSoccerOdds from './LiveSoccerOdds'
import { getSportId, isSameMatchOdd } from '../../utils'

export default function SportsOdds({ settings, loadedSettings, axios, odds, apiUrl, socketUrl, user, selectedOdds, gamesLength, setSelectedOdds, setGamesLength, selectedChampionship, onChangeChampionships, onSelectChampionship, isMobile, sport, onChangeSport, onItemClick, loadSettings, onError, isDarkTheme, selectedMatch, setSelectedMatch, refreshBalance }) {
  const handleOddClick = (match, odd, live = false) => {
    const sameMatchOdd = selectedOdds.find((o) => isSameMatchOdd(o, match));
    const _selectedOdds = [...selectedOdds]

    if (sameMatchOdd) {
      _selectedOdds.splice(_selectedOdds.indexOf(sameMatchOdd), 1)
    }

    if (!sameMatchOdd || sameMatchOdd.odd.id_tipo !== odd.id_tipo) {
      _selectedOdds.push({
        match,
        odd,
        live,
        api: !live && (!settings.tipos || !settings.tipos[match.cotacao_api_id] || !settings.tipos[match.cotacao_api_id][odd.id_tipo])
      })
    }

    setSelectedOdds(_selectedOdds)
  }

  const renderSport = () => {
    if (!loadedSettings)
      return <DefaultSpinner isDarkTheme={isDarkTheme} />

    if (sport === 'live') {
      return (
        <LiveSoccerOdds
          settings={settings}
          apiUrl={apiUrl}
          socketUrl={socketUrl}
          selectedOdds={selectedOdds}
          selectedChampionship={selectedChampionship}
          onOddClick={handleOddClick}
          onChangeChampionships={onChangeChampionships}
          onSelectChampionship={onSelectChampionship}
          isMobile={isMobile}
          loadSettings={loadSettings}
        />
      )
    }

    if (sport === 'casino') {
      return (
        <CasinoGames
          axios={axios}
          settings={settings}
          user={user}
          gamesLength={gamesLength}
          setGamesLength={setGamesLength}
          isMobile={isMobile}
          isDarkTheme={isDarkTheme}
          refreshBalance={refreshBalance}
        />
      )
    }

    const sportId = getSportId(sport);

    return (
      <SportChampionshipOdds
        settings={settings}
        apiUrl={apiUrl}
        selectedOdds={selectedOdds}
        selectedChampionship={selectedChampionship}
        onOddClick={handleOddClick}
        onChangeChampionships={onChangeChampionships}
        onSelectChampionship={onSelectChampionship}
        isMobile={isMobile}
        onError={onError}
        sport={sportId}
        axios={odds}
        isDarkTheme={isDarkTheme}
        selectedMatch={selectedMatch}
        setSelectedMatch={setSelectedMatch}
      />
    )
  }

  if (isMobile) {
    return (
      <div id="page-wrapper" className={classnames('home-m-wrapper', { 'pb-0': sport === 'casino' })}>
        {settings.esportes && Object.keys(settings.esportes).length > 0 && <SportsNavbar settings={settings} sport={sport} onSelect={onChangeSport} isMobile={isMobile} onItemClick={onItemClick} />}
        {renderSport()}
      </div>
    )
  }

  return (
    <>
      {settings.esportes && Object.keys(settings.esportes).length > 0 && <SportsNavbar settings={settings} sport={sport} onSelect={onChangeSport} isMobile={isMobile} onItemClick={onItemClick} />}
      <div id="page-wrapper" className="home-wrapper">
        {renderSport()}
      </div>
    </>
  )
}
