import { useRouter } from 'next/router';
import { useState, useEffect, useLayoutEffect } from 'react';
import { deviceDetect, isMobile, isTablet } from 'react-device-detect';
// import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import classnames from 'classnames';

import AppNavbar from '../components/layout/AppNavbar';
import TicketResumeBar from '../components/layout/TicketResumeBar';
import TicketModal from '../components/modals/TicketModal';
import SportsOdds from '../components/sports/SportsOdds';
import PageSpinner from '../components/layout/PageSpinner';
import DealerAccount from '../components/account/DealerAccount';
import GamblerAccount from '../components/account/GamblerAccount';
import ListBets from '../components/account/ListBets';
import Ticket from '../components/layout/Ticket';
import PrintTable from '../components/sports/PrintTable';
import LoginModal from '../components/modals/LoginModal';
import RegisterModal from '../components/modals/RegisterModal';
import RulesModal from '../components/modals/RulesModal';
import BetRulesModal from '../components/modals/BetRulesModal';
import Popup from '../components/modals/Popup';
import RecoverPasswordModal from '../components/modals/RecoverPasswordModal';
import TopAlert from '../components/layout/TopAlert';
import {
  isRunningOnApp,
  getTotalOdds,
  getBetWinnings,
  generateTicketHtml,
  canCallNative,
  callNative,
  createChampionshipHash,
  getTicketImageDataUrl,
  teamCrestImage,
  showCrest,
  requestErrorAlert,
  getLocalDate,
  desktopPrint,
  generatePrintContent,
  championshipName,
  getInstance,
  fetcher,
  shareMessage,
  formatNumber
} from '../utils';

const canUseDOM = typeof window !== 'undefined';
const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

export default function Home({
  defaultSettings,
  apiUrl,
  socketUrl,
  oddsUrl,
  captchaKey
}) {
  const router = useRouter();
  const affiliateCode = router.query.aff_id;

  const [showTicketPreview, setShowTicketPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedOdds, setSelectedOdds] = useState([]);
  const [betAmount, setBetAmount] = useState(0);
  const [punterName, setPunterName] = useState('');
  const [championships, setChampionships] = useState([]);
  const [gamesLength, setGamesLength] = useState(0);
  const [selectedChampionship, setSelectedChampionship] = useState(null);
  const [settings, setSettings] = useState(defaultSettings);
  const [loadedSettings, setLoadedSettings] = useState(false);
  const [user, setUser] = useState(null);
  const [page, setPage] = useState('sports');
  const [mobile, setMobile] = useState(false);
  const [sport, setSport] = useState('soccer');
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [showBetRules, setShowBetRules] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  // const [loadedCaptcha, setLoadedCaptcha] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('');
  // const [recaptchaRef, setRecaptchaRef] = useState('')
  const [nameLogin, setNameLogin] = useState(null);
  const [isDarkTheme, setIsDarkTheme] = useState(settings.tema_padrao === 1);
  const [userLoading, setUserLoading] = useState(false);
  const [needRecoverPassword, setNeedRecoverPassword] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [ticketCode, setTicketCode] = useState('');

  const axios = getInstance(apiUrl);
  const odds = getInstance(oddsUrl);
  const isApp = isRunningOnApp();
  const ticketToValidate = router.query.validar;

  useEffect(() => {
    loadSettings();
    loadUser();
    setMobile(isMobile || isTablet);
    // loadReCaptcha(captchaKey, () => setLoadedCaptcha(true))
  }, []);

  useEffect(() => {
    if (isApp && settings.versao_aplicativo) {
      checkVersion();
    }

    if (settings.tema_padrao) {
      setIsDarkTheme(settings.tema_padrao === 1);
    }

    if (
      settings.modulo_cassino &&
      settings.habilitar_cassino &&
      Object.keys(settings.esportes).length == 0
    ) {
      setSport('casino');
    }

    // setSport(settings.esportes[0].key)
  }, [settings]);

  useIsomorphicLayoutEffect(() => {
    if (document) {
      const body = document.querySelector('body');

      if (!isDarkTheme) {
        body.classList.add('light');
      } else {
        body.classList.remove('light');
      }
    }
  }, [isDarkTheme]);

  const checkVersion = () => {
    const min = settings.versao_aplicativo;
    const current = callNative('getVersion', '');

    if (current < min) {
      alertify
        .alert(
          'Atenção!',
          'Atualize seu aplicativo para continuar utilizando nossos serviços.'
        )
        .set({
          closable: false,
          closableByDimmer: false,
          labels: { ok: 'Baixar' },
          onok: () => {
            window.open(`${settings.nome_apk}`, '_blank');

            return false;
          }
        });
    }
  };
  useIsomorphicLayoutEffect(() => {
    const updateSize = () => {
      const device = deviceDetect(window.navigator.userAgent);

      if (device.isMobile || device.isTablet) {
        setMobile(true);
      } else {
        setMobile(window.innerWidth <= 768);
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (!ticketToValidate) return;
    fillTicketPreview(ticketToValidate);
  }, [ticketToValidate]);

  useEffect(() => {
    if (!!affiliateCode) {
      setShowRegister(true);
    }

    setShowPopup(!affiliateCode);
  }, [affiliateCode]);

  const loadSettings = () => {
    fetcher(axios, '/api/config')
      .then(({ data }) => {
        const championshipsByHash = {};
        if (data.campeonatos && data.campeonatos.length)
          data.campeonatos.map((campeonato) => {
            const hash = createChampionshipHash(campeonato, [
              'pais',
              'campeonato',
              'esporte'
            ]);
            championshipsByHash[hash] = campeonato;
          });
        data.campeonatos = championshipsByHash;
        setSettings(data);
        setLoading(false);
        setLoadedSettings(true);
      })
      .catch(() => {
        window.location = apiUrl + '/login/';
      });
  };

  const disablePunterField = () => {
    return user && user.nivel == 3;
  };

  const loadUser = () => {
    const token = localStorage.getItem('token');

    if (token === null) {
      setUser(null);
      return;
    }

    fetcher(axios, '/api/usuario')
      .then(({ data }) => {
        setUser(Object.assign({ token }, data));

        if (data && data.nivel == 3) {
          setPunterName(data.nome);
        }

        setUserLoading(false);
      })
      .catch(requestErrorAlert);
  };

  const defaultRequestErrorAlert = (error, showAlert = true) => {
    setLoading(false);
    if (showAlert) requestErrorAlert(error);
  };

  const handleMenuItemClick = (item, value = null) => {
    if (item === 'ticket') {
      if (mobile) {
        showTicket(value);
        return;
      } else {
        askBetCode((val) => showTicket(val));
      }
    } else if (item === 'validate') askBetCode((val) => fillTicketPreview(val));
    else if (item === 'login') setShowLogin(!showLogin);
    else if (item == 'register') setShowRegister(!showRegister);
    else setPage(item);
    setSelectedChampionship(value);
  };

  const handleOddRemoval = (item) => {
    const _selectedOdds = [...selectedOdds];
    _selectedOdds.splice(_selectedOdds.indexOf(item), 1);
    setSelectedOdds(_selectedOdds);
  };

  const handleBetAmount = (val) => {
    if (!val) {
      setBetAmount(0);
      return;
    }

    let amount = 0;

    if (settings.habilitar_centavos) {
      val = '' + val;

      if (+betAmount === 0 && !!val) val = val.replace(/^0+(?!$)/, '');

      amount = val.replace('.', ',');
    } else {
      amount = +val;
    }

    if (!isNaN(amount) && amount > settings.max_bet_amount) {
      setBetAmount(settings.max_bet_amount);
      return;
    }

    if (('' + amount).search(/(?=,*?\d)^(\d+)?(\,)?(\d{1,2})?$/g) >= 0)
      setBetAmount(amount);
  };

  const randomBets = () => {
    const matchList = [];
    championships.map((championship) => {
      championship.matches.map((match) => {
        matchList.push(match);
      });
    });
    const randomSelect = (length) => Math.floor(Math.random() * length);
    const maxMatches =
      matchList.length < settings.palpites_surpresa
        ? matchList.length
        : settings.palpites_surpresa;

    if (matchList[0].hasOwnProperty('livestream')) {
      return;
    }

    const randomMatches = Array.from({ length: maxMatches });
    randomMatches.forEach((v, i, array) => {
      const previousMatches = array
        .filter((m) => !!m)
        .map((m) => ({ index: m.index, partida: m.match.id_partida }));
      const matches = [...matchList];

      if (previousMatches.length) {
        previousMatches.map((m) => matches.splice(m.index, 1));
      }
      let index = randomSelect(matches.length);
      let match = matches[index];
      array[i] = { match, index };
    });
    const randomOdds = randomMatches
      .map(({ match }) => {
        const mainOdds = [match.homeOdd, match.drawOdd, match.awayOdd].filter(
          (o) => o && o.cotacao > settings.cotacao_minima_palpite
        );
        return {
          match,
          odd: mainOdds[randomSelect(mainOdds.length)],
          live: false
        };
      })
      .map((odd) => ({
        ...odd,
        api:
          !settings.tipos ||
          !settings.tipos[odd.match.cotacao_api_id] ||
          !settings.tipos[odd.match.cotacao_api_id][odd.odd.id_tipo]
      }));

    setSelectedOdds(randomOdds);
  };
  const handleChampionshipChange = (data) => {
    if (selectedChampionship && data.indexOf(selectedChampionship) < 0) {
      console.log('champ not found', data);
      setSelectedChampionship(null);
    }
    setChampionships(data);
  };

  const handleBet = (useBonus = false) => {
    const totalOdds = getTotalOdds(selectedOdds, settings);
    const winnings = getBetWinnings(betAmount, totalOdds, settings, useBonus);

    if (!selectedOdds.length) {
      alertify.alert('Atenção!', 'Selecione pelo menos um jogo');
      return;
    }

    if (isNaN(winnings) || winnings <= 0) {
      alertify.alert('Atenção!', 'O valor do prêmio está zerado!');
    }

    const content = `<div class="ticket-scroll">
      <ul class="list-group list-group-flush">
        ${selectedOdds.reduce(
          (acc, item) =>
            acc +
            `<li class="list-group-item">
          <div class="row">
            <div class="col-12 text-truncate text-center">
              <small>${championshipName(item.match)}</small>
            </div>
            <div class="col-12 text-truncate text-center">
              <i class="far fa-clock mr-2"></i>
              ${getLocalDate(item.match.data, settings.fuso_horario).format(
                'DD/MM HH:mm'
              )}
            </div>
          </div>
          <div class="row">
            <div class="col pr-0">
              <div class="d-flex flex-column">
                <div class="text-truncate">
                  ${
                    showCrest(item.match.id_esporte)
                      ? `
                  <img src="${teamCrestImage(
                    item.match.escudo_mandante
                  )}" className="mr-2 align-middle object-fit" width="20" height="20" border="0" />
                  `
                      : ''
                  }
                  ${item.match.mandante}
                </div>
                <div class="text-truncate">
                  ${
                    showCrest(item.match.id_esporte)
                      ? `
                  <img src="${teamCrestImage(
                    item.match.escudo_visitante
                  )}" className="mr-2 align-middle object-fit" width="20" height="20" border="0" />
                  `
                      : ''
                  }
                  ${item.match.visitante}
                </div>
              </div>
            </div>
          </div>
          <div class="row border-top mt-2 pt-2">
            <div class="col-8 pr-0 text-info">
              ${
                item.live
                  ? '<span class="live-blink mr-1 badge badge-danger">Live</span>'
                  : ''
              }
              <small>
                <span><b>${item.odd.categoria}: </b></span>
              </small>
              <small>
                <span>
                  ${
                    item.match.id_esporte == 3 || item.match.id_esporte == 6
                      ? item.odd.id_tipo == 2427 || item.odd.id_tipo == 2621
                        ? item.match.mandante
                        : item.match.visitante
                      : item.odd.tipo
                  }
                </span>
              </small>
            </div>
            <div class="col-4 pl-0 text-truncate text-right text-info">
              <small>
                <i class="fas fa-dollar-sign mr-2"></i>
                <span>${(+item.odd.cotacao).toFixed(2)}</span>
              </small>
            </div>
          </div>
        </li>`,
          ''
        )}
      </ul>
    </div>`;

    alertify
      .confirm(
        'Concluir aposta?',
        content,
        () => finishBet(false, useBonus),
        () => {}
      )
      .set({ padding: true, labels: { ok: 'Concluir', cancel: 'Cancelar' } });
  };

  const finishBet = (confirm = false, useBonus = false, oldParams = undefined) => {
    // updateCaptchaToken()

    const params = {
      CODIGO: ticketCode,
      APOSTADOR: punterName,
      VALOR_APOSTA: betAmount.toString().replace(',', '.'),
      CAPTCHA: captchaToken,
      BONUS: +useBonus
    };

    selectedOdds.forEach(({ match, odd, live, api }, i) => {
      params[`APOSTAS[${i}][tipo]`] = odd.id_tipo;
      params[`APOSTAS[${i}][aposta]`] = odd.id_aposta;
      params[`APOSTAS[${i}][partida]`] =
        match[
          api || live || !match.cotacao_api_id ? 'id_partida' : 'cotacao_api_id'
        ];
      params[`APOSTAS[${i}][cota]`] = odd.cotacao;
      params[`APOSTAS[${i}][ao_vivo]`] = +live;
      params[`APOSTAS[${i}][api]`] = +api;

      if (confirm) {
        params[`APOSTAS[${i}][alteracao_confirmada]`] = 1;
      }
    });

    if (user && settings.solicitar_confirmacao && !confirm) {
      let pass = true;

      alertify
        .prompt(
          'Validação da Aposta',
          'Por favor, informe sua senha',
          '',
          (e, value) => {
            if (!value) {
              e.cancel = true;
              pass = false;
              return;
            }

            params['PASS'] = value;

            requestFinishBet(params)
          },
          () => {
            pass = false;
          }
        )
        .set({
          type: 'password',
          labels: { ok: 'Validar', cancel: 'Cancelar' }
        });

      if (!pass) {
        return;
      }
    } else {
      if (confirm && oldParams['PASS']) {
        params['PASS'] = oldParams['PASS']
      }

      requestFinishBet(params)
    }
  };

  const requestFinishBet = (params) => {
    setLoading(true);
    fetcher(axios, '/api/finalizar', params)
      .then(({ data }) => {
        setLoading(false);

        if (!data.resposta) {
          if (data.solicitar_confirmacao) {
            alertify
              .confirm(
                'Confirmar aposta',
                `Houveram algumas alterações nas cotações escolhidas. O valor estimado do prêmio foi alterado para R$ ${data.novo_premio}, deseja confirmar?`,
                () => finishBet(true, params['BONUS'], params),
                () => {}
              )
              .set({
                padding: true,
                labels: { ok: 'Confirmar', cancel: 'Cancelar' }
              });

            return;
          }

          if (data.reconfirmar) {
            alertify
              .confirm(
                data.mensagem,
                data.descricao,
                () => finishBet(true, params['BONUS'], params),
                () => {}
              )
              .set({
                padding: true,
                labels: { ok: 'Tentar Novamente', cancel: 'Cancelar' }
              });

            return;
          }

          alertify.alert(data.mensagem, data.descricao);

          if (!settings.habilitar_pre_bilhete) {
            setShowLogin(true);
            return;
          }

          if (data.apostas) {
            const _selectedOdds = selectedOdds.filter(
              (o) => !data.apostas.includes(o.id_aposta)
            );
            setSelectedOdds(_selectedOdds);
          }

          return;
        }

        if (!user) {
          alertify
            .confirm(
              'Código de Validação',
              `
            <div class="alert alert-secondary" role="alert">
              <h4 class="alert-heading text-center">
                <strong>${data.PIN}</strong>
              </h4>
              <p>Procure uma banca mais próxima para validar sua aposta!</p>
              <hr>
              <p class="mb-0">As cotações escolhidas estão sujeitas a alterações, podendo ocorrer variações no valor do prêmio.</p>
            </div>
          `,
              () => shareTicket(data.PIN, betAmount, false),
              () => {}
            )
            .set({
              padding: true,
              labels: { ok: 'Compartilhar', cancel: 'Fechar' }
            });
        } else if (data.cod) {
          const { cod } = data;
          setTicketCode(cod);

          const alertTicketSuccess = (val) => {
            alertify
              .confirm(
                'Validado com Sucesso!',
                `Seu bilhete código <strong style="color:green;">${cod}</strong> encontra-se validado.`,
                () =>
                  settings.compartilhar_comprovante
                    ? shareTicket(cod, val)
                    : {},
                () => showTicket(cod)
              )
              .set({
                padding: true,
                labels: {
                  ok: settings.compartilhar_comprovante
                    ? 'Compartilhar'
                    : 'Fechar',
                  cancel: 'Ver Bilhete'
                },
                onclose: () => {}
              });
          };

          loadTicket(cod).then((data) => {
            if (!data.resposta) {
              alertify.alert(data.mensagem, data.descricao);
              return;
            }

            if (user.nivel != 3 && canPrintTicket(data)) {
              alertify
                .confirm(
                  'Imprimir',
                  `Deseja imprimir o bilhete?`,
                  () => {
                    if (isApp) {
                      callNative('printTicket', data);
                    } else {
                      desktopPrint(
                        generatePrintContent(data.BILHETE, data.ITENS, settings)
                      );
                    }
                    setTimeout(
                      () => alertTicketSuccess(data.BILHETE.valor),
                      500
                    );
                  },
                  () =>
                    setTimeout(
                      () => alertTicketSuccess(data.BILHETE.valor),
                      500
                    )
                )
                .set({
                  padding: true,
                  labels: { ok: 'Sim', cancel: 'Não' }
                });

              return;
            }

            alertTicketSuccess(data.BILHETE.valor);
          });
        }

        setShowTicketPreview(false);
        resetBetPreview();
        loadUser();
      })
      .catch(defaultRequestErrorAlert);
  }

  const resetBetPreview = () => {
    setSelectedOdds([]);
    setBetAmount(0);
    setTicketCode('');
  };

  const canPrintTicket = (data) => {
    if ((mobile && !isApp) || !data.BILHETE.valido) return false;

    if (data.BILHETE.ultimo_bilhete) return settings.reimprimir_ultima_aposta;
    return settings.reimprimir_aposta;
  };

  const loadTicket = (code) => {
    setLoading(true);
    return fetcher(axios, '/api/bilhete', `cod=${code}`)
      .then(({ data }) => {
        setLoading(false);
        if (!data.resposta) {
          alertify.alert(data.mensagem, data.descricao);
          throw 0;
        }

        return data;
      })
      .catch((err) => err && defaultRequestErrorAlert(err));
  };

  const showTicket = (code) => {
    loadTicket(code).then((data) => {
      if (!data.BILHETE) {
        alertify.alert('Atenção', 'Não foi possível carregar o bilhete.');
        return;
      }

      if (!data.BILHETE.valido) {
        alertify.alert('Atenção', 'Este bilhete ainda não foi validado.');
        return;
      }

      alertify
        .confirm(
          'Bilhete',
          generateTicketHtml(data, settings),
          () => shareTicket(code, data.BILHETE.valor),
          () => {}
        )
        .set({
          padding: false,
          labels: {
            ok: settings.compartilhar_comprovante ? 'Compartilhar' : 'Ok',
            cancel: 'Fechar'
          }
        });
    });
  };

  const askBetCode = (callback) => {
    alertify
      .prompt(
        'Buscar Aposta',
        'Informe o código da aposta',
        '',
        (e, value) => {
          if (!value) {
            e.cancel = true;
            return;
          }
          callback(value);
        },
        () => {}
      )
      .set({ type: 'text', labels: { ok: 'Buscar', cancel: 'Cancelar' } });
  };

  const fillTicketPreview = (code) => {
    loadTicket(code).then((data) => {
      const odds = data.ITENS.map((item) => ({
        match: {
          id_partida: item.id_partida,
          pais: item.PAIS,
          campeonato: item.PAIS,
          mandante: item.MANDANTE,
          escudo_mandante: item.ESCUDO_MANDANTE,
          visitante: item.VISITANTE,
          escudo_visitante: item.ESCUDO_VISITANTE,
          data: item.DATA_HORA
        },
        odd: {
          cotacao: item.COTA,
          id_aposta: item.id_api || item.id_cotacao,
          id_tipo: item.id_tipo,
          categoria: item.CATEGORIA,
          tipo: item.TIPO
        },
        live: item.AO_VIVO,
        api: !!item.id_api
      }));

      setPunterName(data.BILHETE.nome);
      setBetAmount(
        data.BILHETE.valor || data.BILHETE.valor_aposta.replace(',', '.')
      );
      setSelectedOdds(odds);
      setShowTicketPreview(true);
      setTicketCode(code);
    });
  };

  const shareTicket = (code, value, isValid = true) => {
    if (!settings.compartilhar_comprovante) {
      return;
    }

    const message = isValid
      ? `Código do bilhete: ${code}${
          value ? '\r\nValor: R$ ' + formatNumber(value) : ''
        }\r\nVer bilhete: ${window.location.origin}/ticket?i=${code}\r\n${
          settings.modulo_bilhete_ao_vivo
            ? `Acompanhe ao vivo: ${window.location.origin}/ticket/live?i=${code}`
            : ''
        }`
      : `Código da minha pré-aposta: (${code})\r\n\r\nClique no link abaixo para validar a pré-aposta rapidamente!\r\n\r\n${window.location.origin}/?validar=${code}`;

    if (
      isValid &&
      canCallNative('shareImage') &&
      settings.compartilhar_imagem
    ) {
      shareTicketImage(code, message);

      return;
    }

    shareMessage(message);
  };

  const shareTicketImage = (code, message) => {
    setLoading(true);
    getTicketImageDataUrl(apiUrl, code)
      .then((url) => {
        setLoading(false);
        callNative('shareImage', { texto: message, imagem: url });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        alertify.alert(
          'Falha',
          'Ocorreu um erro ao gerar a imagem do bilhete.'
        );
      });
  };

  const goToSport = (s) => {
    if (!settings.habilitar_misturar_modalidades) {
      setSelectedOdds([]);
      setBetAmount(0);
      setTicketCode('');
    }

    if (page !== 'sports') {
      setPage('sports');
    }

    if (sport !== s) {
      setSport(s);
    }
  };

  // const getCaptchaToken = (captchaToken) => {
  //   setCaptchaToken(captchaToken)
  // }
  //
  // const updateCaptchaToken = () => {
  //   recaptchaRef.execute()
  // }

  const refreshBalance = () => {
    setUserLoading(true);
    loadUser();
  };

  const renderPage = () => {
    if (page === 'sports') {
      return (
        <SportsOdds
          settings={settings}
          loadedSettings={loadedSettings}
          axios={axios}
          odds={odds}
          apiUrl={apiUrl}
          socketUrl={socketUrl}
          user={user}
          selectedOdds={selectedOdds}
          gamesLength={gamesLength}
          setSelectedOdds={setSelectedOdds}
          setGamesLength={setGamesLength}
          selectedChampionship={selectedChampionship}
          onChangeChampionships={handleChampionshipChange}
          onSelectChampionship={(c) => setSelectedChampionship(c)}
          isMobile={mobile}
          sport={sport}
          onChangeSport={goToSport}
          onItemClick={handleMenuItemClick}
          loadSettings={loadSettings}
          onError={(err) => defaultRequestErrorAlert(err, false)}
          isDarkTheme={isDarkTheme}
          selectedMatch={selectedMatch}
          setSelectedMatch={setSelectedMatch}
          refreshBalance={refreshBalance}
        />
      );
    }
    if (page === 'account' || page === 'deposits') {
      if (user.nivel === 3) {
        return (
          <GamblerAccount
            apiUrl={apiUrl}
            axios={axios}
            setLoading={setLoading}
            user={user}
            sport={sport}
            isMobile={mobile}
            onChangeSport={goToSport}
            onItemClick={handleMenuItemClick}
            settings={settings}
            loadUser={loadUser}
            onError={defaultRequestErrorAlert}
            isDarkTheme={isDarkTheme}
            depositLinkClicked={page === 'deposits'}
          />
        );
      }

      return (
        <DealerAccount
          axios={axios}
          setLoading={setLoading}
          user={user}
          sport={sport}
          isMobile={mobile}
          onChangeSport={goToSport}
          onItemClick={handleMenuItemClick}
          settings={settings}
          onError={defaultRequestErrorAlert}
          isDarkTheme={isDarkTheme}
        />
      );
    }

    if (page === 'bets') {
      return (
        <ListBets
          axios={axios}
          user={user}
          isApp={isApp}
          sport={sport}
          settings={settings}
          isMobile={mobile}
          canPrintTicket={canPrintTicket}
          onShareTicket={shareTicket}
          onChangeSport={goToSport}
          onShowTicket={showTicket}
          onItemClick={handleMenuItemClick}
          onError={defaultRequestErrorAlert}
        />
      );
    }

    if (page === 'table') {
      return (
        <PrintTable
          axios={odds}
          settings={settings}
          isMobile={mobile}
          championships={championships}
          sport={sport}
          onSelect={goToSport}
          onItemClick={handleMenuItemClick}
        />
      );
    }

    return null;
  };

  const renderContent = () => {
    if (mobile) {
      return (
        <>
          {renderPage()}
          <TicketModal
            user={user}
            settings={settings}
            odds={selectedOdds}
            amount={betAmount}
            name={punterName}
            disableName={disablePunterField()}
            show={showTicketPreview}
            onResetClick={() => resetBetPreview()}
            onChangeBetAmount={handleBetAmount}
            onChangeGamblerName={(val) => setPunterName(val)}
            onRemoveOddClick={handleOddRemoval}
            onPlaceBet={handleBet}
            randomBets={randomBets}
            onHide={() => setShowTicketPreview(false)}
            selectedMatch={selectedMatch}
            setSelectedMatch={setSelectedMatch}
          />
          {!user && (
            <>
              {/* {loadedCaptcha &&  */}
              <LoginModal
                axios={axios}
                show={showLogin}
                onHide={() => handleMenuItemClick('login')}
                captchaKey={captchaKey}
                setLoading={setLoading}
                loadUser={loadUser}
                setNameLogin={setNameLogin}
                nameLogin={nameLogin}
                loadSettings={loadSettings}
                onError={defaultRequestErrorAlert}
                settings={settings}
                setNeedRecoverPassword={setNeedRecoverPassword}
              />
              {/* } */}
              <RegisterModal
                axios={axios}
                show={showRegister}
                affiliateCode={affiliateCode}
                onHide={() => handleMenuItemClick('register')}
                setLoading={setLoading}
                setNameLogin={setNameLogin}
                setShowLogin={setShowLogin}
                loadUser={loadUser}
                captchaKey={captchaKey}
                loadSettings={loadSettings}
                onError={defaultRequestErrorAlert}
                settings={settings}
                showRules={() => setShowRules(true)}
              />
            </>
          )}
          {sport !== 'casino' && (
            <TicketResumeBar
              settings={settings}
              odds={selectedOdds}
              amount={betAmount}
              onBetClick={() => setShowTicketPreview(true)}
            />
          )}
          <RulesModal
            show={showRules}
            settings={settings}
            onHide={() => setShowRules(false)}
            isMobile={true}
          />
          <BetRulesModal
            show={showBetRules}
            settings={settings}
            onHide={() => setShowBetRules(false)}
            isMobile={true}
          />
          {loading && <PageSpinner isDarkTheme={isDarkTheme} />}
          <RecoverPasswordModal
            show={needRecoverPassword}
            isDarkTheme={isDarkTheme}
            onHide={() => setNeedRecoverPassword(false)}
            axios={axios}
            setLoading={setLoading}
            onError={defaultRequestErrorAlert}
          />
        </>
      );
    }

    return (
      <>
        <div className="d-flex justify-content-between">
          {renderPage()}
          {sport !== 'casino' ? (
            <div
              className={classnames([
                'flex-column sidebar-right',
                { 'bg-brown': isDarkTheme }
              ])}
            >
              <Ticket
                settings={settings}
                odds={selectedOdds}
                amount={betAmount}
                name={punterName}
                disableName={disablePunterField()}
                onResetClick={() => resetBetPreview()}
                onChangeBetAmount={handleBetAmount}
                randomBets={randomBets}
                onChangeGamblerName={(val) => setPunterName(val)}
                onRemoveOddClick={handleOddRemoval}
                onPlaceBet={handleBet}
                user={user}
                selectedMatch={selectedMatch}
                setSelectedMatch={setSelectedMatch}
              />
            </div>
          ) : (
            ''
          )}
          {loading && <PageSpinner isDarkTheme={isDarkTheme} />}
        </div>
        {!user && (
          <>
            <LoginModal
              axios={axios}
              show={showLogin}
              onHide={() => handleMenuItemClick('login')}
              captchaKey={captchaKey}
              setLoading={setLoading}
              loadUser={loadUser}
              setNameLogin={setNameLogin}
              nameLogin={nameLogin}
              loadSettings={loadSettings}
              onError={defaultRequestErrorAlert}
              settings={settings}
              setNeedRecoverPassword={setNeedRecoverPassword}
            />
            <RegisterModal
              axios={axios}
              show={showRegister}
              affiliateCode={affiliateCode}
              onHide={() => handleMenuItemClick('register')}
              setLoading={setLoading}
              setNameLogin={setNameLogin}
              setShowLogin={setShowLogin}
              loadUser={loadUser}
              captchaKey={captchaKey}
              loadSettings={loadSettings}
              onError={defaultRequestErrorAlert}
              settings={settings}
              showRules={() => setShowRules(true)}
            />
          </>
        )}
        <RulesModal
          show={showRules}
          settings={settings}
          onHide={() => setShowRules(false)}
        />
        <BetRulesModal
          show={showBetRules}
          settings={settings}
          onHide={() => setShowBetRules(false)}
        />
        <RecoverPasswordModal
          show={needRecoverPassword}
          isDarkTheme={isDarkTheme}
          onHide={() => setNeedRecoverPassword(false)}
          axios={axios}
          setLoading={setLoading}
          onError={defaultRequestErrorAlert}
        />
      </>
    );
  };

  return (
    <>
      {settings.mensagem_topo != '' && (
        <TopAlert settings={settings} isMobile={mobile} />
      )}
      <AppNavbar
        apiUrl={apiUrl}
        affiliateCode={affiliateCode}
        axios={axios}
        user={user}
        page={page}
        championships={championships}
        gamesLength={gamesLength}
        sport={sport}
        selectedChampionship={selectedChampionship}
        onItemClick={handleMenuItemClick}
        isMobile={mobile}
        settings={settings}
        setLoading={setLoading}
        loadUser={loadUser}
        loadSettings={loadSettings}
        showRules={() => setShowRules(true)}
        showBetRules={() => setShowBetRules(true)}
        userLoading={userLoading}
        setUserLoading={setUserLoading}
        onError={defaultRequestErrorAlert}
        refreshBalance={refreshBalance}
      />
      {renderContent()}
      {settings.popup && loadedSettings && (
        <Popup settings={settings} defaultShow={showPopup} />
      )}
    </>
  );
}

export { getDefaultStaticProps as getStaticProps } from '../utils';
