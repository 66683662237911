import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import classnames from 'classnames'

export default function TopAlert({ settings, isMobile }) {
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (document) {
      const body = document.querySelector('body')

      if (isMobile) {
        body.classList.remove('overflow-hidden')
      }

      if (show && !isMobile) {
        window.history.scrollRestoration = 'manual'
        body.classList.add('overflow-hidden')
      }

    }
  }, [show, isMobile])

  return (
    <Alert
      variant="primary"
      show={show}
      onClose={() => setShow(false)}
      className={classnames('top-alert mb-0 text-center rounded-0', { 'text-white': settings.cor_texto_topo, 'text-dark': !settings.cor_texto_topo })}
      style={{ backgroundColor: settings.background_topo, border: 'none' }}
      dismissible
    >
      {settings.mensagem_topo}
    </Alert>
  )
}
