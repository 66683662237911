import { useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'

import GamblerCommissionModal from '../modals/GamblerCommissionModal'
import Icon from '../utils/Icon'
import { copyText, formatNumber, shareMessage } from '../../utils'

export default function AccountTable({ user, axios, isMobile, isDarkTheme, settings, onError }) {
  const [showGamblerModal, setShowGamblerModal] = useState(false)
  const userAccount = user.conta_bancaria
  const userPix = user.pix
  const affiliateUrl = `${window.location.origin}/?aff_id=${user.codigo_referencia}`

  const renderBankInfos = () => {
    if (!userAccount) {
      return null
    }

    return (
      <>
        <tr>
          <td colSpan="12">
            <h5 className="mb-0 text-uppercase">
              Dados Bancários
            </h5>
          </td>
        </tr>
        <tr>
          <td>Banco</td>
          <td>{userAccount.banco}</td>
        </tr>
        <tr>
          <td>Agência</td>
          <td>{userAccount.agencia}</td>
        </tr>
        <tr>
          <td>Conta</td>
          <td>{userAccount.conta}</td>
        </tr>
        <tr>
          <td>Tipo de Conta</td>
          <td>{userAccount.conta_tipo}</td>
        </tr>
      </>
    )
  }

  const renderPixInfos = () => {
    if (!userPix) {
      return null
    }

    return (
      <>
        <tr>
          <td colSpan="12">
            <h5 className="mb-0 text-uppercase">
              Dados do Pix
            </h5>
          </td>
        </tr>
        <tr>
          <td>Tipo de chave pix</td>
          <td>{userPix.tipo_nome}</td>
        </tr>
        <tr>
          <td>Chave pix</td>
          <td>{userPix.chave}</td>
        </tr>
      </>
    )
  }

  return (
    <>
      <Table variant={isDarkTheme ? 'dark' : ''} striped className="rounded">
        <tbody>
          <tr>
            <td>Nome</td>
            <td>{user.nome}</td>
          </tr>
          <tr>
            <td>CPF</td>
            <td>{user.cpf}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{user.email}</td>
          </tr>
          {user.estado && <tr>
            <td>Estado</td>
            <td>{user.estado}</td>
          </tr>}
          {user.cidade && <tr>
            <td>Cidade</td>
            <td>{user.cidade}</td>
          </tr>}
          {renderBankInfos()}
          {renderPixInfos()}
          {settings.cadastrar_apostador && (settings.comissao_indicacao > 0 || user.comissao_resultado > 0) && user.codigo_referencia && <>
            <tr>
              <td colSpan="2">
                <h5 className="mb-0 text-center text-uppercase">
                  Indique e Ganhe
                </h5>
              </td>
            </tr>
            {settings.comissao_indicacao > 0 && <tr>
              <td colSpan="2">
                <p className="mb-0 text-center">
                  Indique um amigo e receba uma comissão de {settings.tipo_comissao_indicacao == 1 ? `R$ ${formatNumber(settings.comissao_indicacao)}` : `${settings.comissao_indicacao}%`} para {settings.recorrencia_comissao_indicacao == 1 ? 'qualquer' : 'o primeiro'} depósito
                </p>
              </td>
            </tr>}
            <tr>
              <td colSpan="2">
                <Row>
                  <Col md={6}>
                    <InputGroup className={isMobile ? 'mb-2' : ''}>
                      <InputGroup.Prepend style={{ cursor: 'pointer' }}>
                        <InputGroup.Text onClick={() => copyText(affiliateUrl)}>
                          <Icon name="copy" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        defaultValue={affiliateUrl}
                        className="no-shadow"
                        style={{ cursor: 'pointer' }}
                        onClick={() => copyText(affiliateUrl)}
                        readOnly
                      />
                    </InputGroup>
                  </Col>
                  <Col md={3}>
                    <Button variant="success" className={isMobile ? 'mb-2' : ''} onClick={() => shareMessage(affiliateUrl)} block>
                      <Icon style="fab" name="whatsapp" margin /> Compartilhar
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button variant="secondary" onClick={() => setShowGamblerModal(true)} block>
                      <Icon name='comments-dollar' margin /> Meus Ganhos
                    </Button>
                  </Col>
                </Row>
              </td>
            </tr>
          </>}
        </tbody>
      </Table>
      <GamblerCommissionModal
        show={showGamblerModal}
        axios={axios}
        user={user}
        isDarkTheme={isDarkTheme}
        onHide={() => setShowGamblerModal(false)}
        onError={onError}
      />
    </>
  )
}
