import { Row, Col, FormControl, InputGroup, ListGroup } from 'react-bootstrap'
import classnames from 'classnames'

import Icon from '../utils/Icon'
import styles from '../../styles/GamesCasino.module.css'

export default function SearchCassinoGame({
  isMobile,
  isDarkTheme,
  queryName,
  queryProvider,
  queryCategory,
  categories,
  providers,
  setQueryName,
  setQueryCategory,
  setQueryProvider,
}) {
  if (isMobile) {
    return (
      <>
        <InputGroup className="mt-2">
          <InputGroup.Prepend>
            <InputGroup.Text className={classnames(['border-0 rounded-0', { 'bg-dark': isDarkTheme }])}>
              <Icon name="search" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
            placeholder="Buscar jogo"
            value={queryName}
            onChange={({ target }) => setQueryName(target.value)}
          />
        </InputGroup>
        <InputGroup className="mt-2">
          <InputGroup.Prepend>
            <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
              <Icon name="gamepad-alt" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            as="select"
            className={classnames(['border-0 form-control', { 'bg-dark text-light': isDarkTheme }])}
            custom
            onChange={({ target }) => setQueryProvider(target.value)}
          >
            <option value={0}>{queryProvider != 0 ? 'Todos' : 'Selecionar Provedor'}</option>
            {providers.sort((a, b) => a.name > b.name ? 1 : -1).map(provider => <option key={provider.id} value={provider.id}>{provider.name}</option>)}
          </FormControl>
        </InputGroup>
        {categories.length > 0 &&
          <ListGroup className={classnames(styles.categoryList, 'mt-2')} horizontal>
            <ListGroup.Item className={classnames(styles.categoryListItem, { 'text-light': isDarkTheme, [styles.active]: queryCategory == 0 })} onClick={() => setQueryCategory(0)} >
            {queryProvider > 0
              ? 'Todos'
              : <>
                <Icon name="star" margin />
                Populares
              </>
            }
            </ListGroup.Item>
            {categories.map(category =>
              <ListGroup.Item className={classnames(styles.categoryListItem, { 'text-light': isDarkTheme, [styles.active]: queryCategory == category.id })} key={category.id} onClick={() => setQueryCategory(category.id)}>
                {category.name}
              </ListGroup.Item>
            )}
          </ListGroup>
        }
      </>
    )
  }

  return (
    <Row className="px-4">
      <Col md={6}>
        <InputGroup >
          <InputGroup.Prepend>
            <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
              <Icon name="search" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
            placeholder="Pesquisar jogo"
            value={queryName}
            onChange={({ target }) => setQueryName(target.value)}
          />
        </InputGroup>
      </Col>
      <Col md={6}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
              <Icon name="gamepad-alt" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            as="select"
            className={classnames(['border-0 form-control', { 'bg-dark text-light': isDarkTheme }])}
            custom
            onChange={({ target }) => setQueryProvider(target.value)}
          >
            <option value={0}>{queryProvider != 0 ? 'Todos' : 'Selecionar Provedor'}</option>
            {providers.sort((a, b) => a.name > b.name ? 1 : -1).map(provider => <option key={provider.id} value={provider.id}>{provider.name}</option>)}
          </FormControl>
        </InputGroup>
      </Col>
      <Col className="mt-2">
        {categories.length > 0 &&
          <ListGroup className={classnames(styles.categoryList, 'virtual-scroll h-95  mt-2')} horizontal>
            <ListGroup.Item className={classnames(styles.categoryListItem, { 'text-light': isDarkTheme, [styles.active]: queryCategory == 0 })} onClick={() => setQueryCategory(0)} active={queryCategory == 0}>
              {queryProvider > 0
                ? 'Todos'
                : <>
                  <Icon name="star" margin />
                  Populares
                </>
              }
            </ListGroup.Item>
            {categories.map(category =>
              <ListGroup.Item className={classnames(styles.categoryListItem, { 'text-light': isDarkTheme, [styles.active]: queryCategory == category.id })} key={category.id} onClick={() => setQueryCategory(category.id)} active={queryCategory == category.id}>
                {category.name}
              </ListGroup.Item>
            )}
          </ListGroup>
        }
      </Col>
    </Row>
  )
}
