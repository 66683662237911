import { useState } from 'react'
import classnames from 'classnames'
import { Badge, Button, ButtonGroup, Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'

import ColAuto from '../utils/ColAuto'
import Icon from '../utils/Icon'
import {
  formatNumber, getTotalOdds, getBetWinnings, championshipName,
  showCrest, getLocalDate
} from '../../utils'
import ImageFallback from '../utils/ImageFallback'

export default function Ticket({ settings, odds, amount, name, disableName = false, onResetClick, onChangeBetAmount, randomBets, onChangeGamblerName, onRemoveOddClick, onPlaceBet, user, setSelectedMatch }) {
  const [useBonus, setUseBonus] = useState(false)

  const totalOdds = getTotalOdds(odds, settings)
  const winnings = getBetWinnings(amount, totalOdds, settings, useBonus)
  const isDarkTheme = settings.tema_padrao === 1

  const renderBadge = () => {
    if (odds.length > 0) {
      return (
        <Badge variant="warning" className='align-baseline mr-2'>
          {odds.length}
        </Badge>
      )
    }

    return <Icon name="receipt mr-2" />
  }

  const renderMatchList = () => {
    if (odds.length == 0) {
      return (
        <div className={classnames(['d-flex h-100 flex-column justify-content-center align-items-center', { 'bg-dark': isDarkTheme }])}>
          <div className="text-brown"><Icon name="ticket-alt" /></div>
          <div className="text-brown"><span>Bilhete vazio</span></div>
        </div>
      )
    }

    return (
      <ListGroup variant="flush">
        {odds.map((item) => (
          <ListGroup.Item key={item.match.id_partida}>
            <Row className="mb-3">
              <Col className="text-truncate">
                <small>{championshipName(item.match)}</small>
              </Col>
              {!item.live &&
                <ColAuto>
                  <h6>
                    <span className="badge badge-secondary p-0">
                      <Button variant='secondary' size="sm" className="pr-1 p-0" onClick={() => setSelectedMatch(item.match)}>
                        <Icon name="exchange-alt" className=" fa-rotate-90 mr-2" />
                        Alterar
                      </Button>
                    </span>
                  </h6>
                </ColAuto>
              }
            </Row>
            <Row>
              <Col xs={8} className="pr-0">
                <div className="d-flex flex-column">
                  <div className="text-truncate">
                    {showCrest(item.match.id_esporte) &&
                      <ImageFallback
                        className='mr-2 mb-2 align-middle'
                        src={item.match.escudo_mandante}
                        fallBackImg={'/images/shields/shield.png'}
                        width={20}
                        height={20}
                      />}
                    {item.match.mandante}
                  </div>
                  <div className="text-truncate">
                    {showCrest(item.match.id_esporte) &&
                      <ImageFallback
                        className='mr-2 mb-1 align-middle'
                        src={item.match.escudo_visitante}
                        fallBackImg={'/images/shields/shield.png'}
                        width={20}
                        height={20}
                      />}
                    {item.match.visitante}
                  </div>
                </div>
              </Col>
              <Col xs={4} className="pl-0">
                <div className="d-flex flex-column">
                  <div role="button" className="text-right" onClick={() => onRemoveOddClick(item)}>
                    <Icon name="trash" />
                  </div>
                  <div className="text-truncate text-right small">
                    <Icon name="clock" /> {getLocalDate(item.match.data, settings.fuso_horario).format('HH:mm')}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="border-top mt-2 pt-2">
              <Col xs={8} className="pr-0 text-info">
                {item.live && <Badge variant="danger" className="live-blink mr-1">Live</Badge>}
                <small><b>{item.odd.categoria}: </b></small>
                <small>
                  {item.match.id_esporte == 3 || item.match.id_esporte == 6 ?
                    <>{item.odd.id_tipo == 2427 || item.odd.id_tipo == 2621 ? item.match.mandante : item.match.visitante}</>
                    :
                    <>{item.odd.tipo}{item.odd.jogador ? `: ${item.odd.jogador}` : ''}</>
                  }
                </small>
              </Col>
              <Col xs={4} className="pl-0 text-truncate text-right text-info">
                <small><i className="fas fa-dollar-sign mr-2"></i>{(+item.odd.cotacao).toFixed(2)}</small>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    )
  }

  return (
    <>
      <header className={classnames(['ticket-header pt-2 pb-2', { 'bg-white': !isDarkTheme }])} >
        <Row>
          <Col>
            <h5 className="text-uppercase my-1 ml-2">
              {renderBadge()}
              Bilhete
            </h5>
          </Col>
          {settings.palpites_surpresa > 0 && <ColAuto className="mr-3">
            <Button block variant="secondary" onClick={() => randomBets()}>
              <Icon name="random" />
            </Button>
          </ColAuto>}
        </Row>
      </header>
      <div className="ticket-scroll" style={{ height: (user && user.nivel === 3 && user.saldo_bonus > 0 ? 46 : 50) + 'vh' }}>
        {renderMatchList()}
      </div>
      <div className="ticket-content mt-2 pl-2 pr-2">
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-column">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <Icon name="user" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  placeholder="Apostador"
                  value={name}
                  disabled={disableName}
                  autoComplete="off"
                  spellCheck={false}
                  onChange={(e) => onChangeGamblerName(e.target.value)}
                  maxLength="14"
                  pattern="[A-Za-z\s]"
                />
              </InputGroup>
              <Row className="mt-2 mr-0">
                <Col className="pr-0 text-truncate">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="rounded-left">
                        <Icon name="usd-square" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      className="rounded-0"
                      placeholder="Valor"
                      value={amount}
                      inputMode="numeric"
                      pattern={settings.habilitar_centavos ? "^(\d)?(\,)?(\d{1,2})?$" : "[\d*]"}
                      autoComplete="off"
                      spellCheck={false}
                      onChange={(e) => onChangeBetAmount(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col className="pl-2 b-222 rounded-right text-truncate">
                  <div className="d-flex justify-content-between text-ddd">
                    <div><Icon name="exchange-alt" /></div>
                    <div className="text-right">{formatNumber(winnings)}</div>
                  </div>
                </Col>
              </Row>
              <ButtonGroup className="mt-2">
                {[5, 10, 20, 50, 100].map(value => <Button key={value} variant="secondary" onClick={() => onChangeBetAmount(value)}>
                  {value}
                </Button>)}
              </ButtonGroup>
              {user && user.nivel === 3 && user.saldo_bonus > 0 && <Form.Check
                checked={useBonus}
                className={classnames('mt-2', { 'text-light': isDarkTheme })}
                type="checkbox"
                label="Usar Saldo de Bônus"
                onChange={(e) => setUseBonus(e.target.checked)}
              />}
              <Row className="mt-2">
                <ColAuto className="pr-2">
                  <Button variant="secondary" block onClick={onResetClick}>
                    <Icon name="undo" />
                  </Button>
                </ColAuto>
                <div className="col pl-0">
                  <Button variant="primary" block onClick={() => onPlaceBet(useBonus)}>
                    <div className="d-flex justify-content-center">
                      <div className="mr-3"><Icon name="check" /></div>
                      <div>Concluir</div>
                    </div>
                  </Button>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
